import { Grid, makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { defaultSectionWidth, smallSubtitle, smallText, tinyText } from '../styles/dimensions';
import content from '../content/showcase/showcase.json';
import MetroSans from '../fonts/metro-sans/metroSans';
import colors from '../styles/colors';
import { Routes } from '../consts/routes';
import RigrafExpanded from '../fonts/rigraf/expanded/rigrafExpanded';
import { descriptions, titles } from '../consts/seo';

const Showcase = () => {
	const classes = useClasses();

	return (
		<Layout>
			<SEO title={titles.showcase.main} description={descriptions.showcase.main} />

			<section className={classes.section} data-scroll data-scroll-speed="2">
				<Grid container spacing={4}>
					<Grid item xs={12} sm={6}>
						<h1 className={classes.header}>{content.title}</h1>
					</Grid>
					<Grid item xs={12} sm={6} classes={{ item: classes.descriptionColumn }}>
						<p className={classes.description}>{content.description}</p>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Link to={Routes.showcaseQap} className={classes.link}>
							<StaticImage
								src="../images/index/qap.jpg"
								alt=""
								layout="constrained"
								loading="lazy"
								placeholder="blurred"
								className={classes.image}
							/>
							<span className={`${classes.itemText} ${classes.itemTextBlack}`}>{content.qap}</span>
						</Link>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Link to={Routes.showcaseThng} className={classes.link}>
							<StaticImage
								src="../images/index/thng.jpg"
								alt=""
								layout="constrained"
								loading="lazy"
								placeholder="blurred"
								className={classes.image}
							/>
							<span className={`${classes.itemText} ${classes.itemTextWhite}`}>{content.thng}</span>
							<span className={classes.smartCityChip}>{content.smartCity}</span>
						</Link>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Link to={Routes.showcaseVestur} className={classes.link}>
							<StaticImage
								src="../images/index/vestur.jpg"
								alt=""
								layout="constrained"
								loading="lazy"
								placeholder="blurred"
								className={classes.image}
							/>
							<span className={`${classes.itemText} ${classes.itemTextBlack}`}>{content.vestur}</span>
							<span className={classes.smartCityChip}>{content.smartCity}</span>
						</Link>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Link to={Routes.showcaseEnzkreis} className={classes.link}>
							<StaticImage
								src="../images/index/enzkreis.jpg"
								alt=""
								layout="constrained"
								loading="lazy"
								placeholder="blurred"
								className={classes.image}
							/>
							<span className={`${classes.itemText} ${classes.itemTextWhite}`}>{content.enzkreis}</span>
							<span className={classes.smartCityChip}>{content.smartCity}</span>
						</Link>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Link to={Routes.showcaseTerra} className={classes.link}>
							<StaticImage
								src="../images/index/terra.jpg"
								alt=""
								layout="constrained"
								loading="lazy"
								placeholder="blurred"
								className={classes.image}
							/>
							<span className={`${classes.itemText} ${classes.itemTextWhite}`}>{content.terra}</span>
						</Link>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Link to={Routes.showcaseStabler} className={classes.link}>
							<StaticImage
								src="../images/index/stabler.jpg"
								alt=""
								layout="constrained"
								loading="lazy"
								placeholder="blurred"
								className={classes.image}
							/>
							<span className={`${classes.itemText} ${classes.itemTextBlack}`}>{content.stabler}</span>
						</Link>
					</Grid>
				</Grid>
			</section>
		</Layout>
	);
};
export default Showcase;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		padding: '0.375em 2em 2em',
		'@media (max-width: 1049px)': {
			padding: '4.75em 2em 2em',
		},
		'@media (max-width: 749px)': {
			padding: '7.75em 2em 2em',
		},
		'@media (max-width: 599px)': {
			padding: '7.75em 1em 4em',
		},
		'@media (min-width: 600px) and (min-height: 1000px)': {
			padding: '4em 2em 1.5em',
		},
	},
	descriptionColumn: {
		display: 'flex',
		alignItems: 'center',
	},
	header: {
		margin: 0,
		'@media (max-width: 46.875em)': {
			...smallSubtitle,
		},
	},
	description: {
		margin: 0,
		fontFamily: MetroSans.book,
		color: colors.textGrey,
	},
	link: {
		position: 'relative',
		display: 'inline-block',
	},
	image: {
		position: 'relative',
		zIndex: -1,
	},
	itemText: {
		position: 'absolute',
		left: '3.25em',
		top: '3.25em',
		fontFamily: RigrafExpanded.semiBold,
		'@media (max-width: 1263px)': {
			left: '1em',
			top: '1em',
		},
		'@media (max-width: 399px)': {
			left: '0.5em',
			top: '0.5em',
		},
	},
	itemTextBlack: {
		color: colors.textBlack,
	},
	itemTextWhite: {
		color: colors.white,
	},
	smartCityChip: {
		position: 'absolute',
		top: '24px',
		right: 0,
		backgroundColor: colors.textBlack,
		color: colors.white,
		fontFamily: RigrafExpanded.semiBold,
		padding: '12px',
		fontSize: smallText.fontSize,
		lineHeight: 1,

		'&::after': {
			content: "'|'",
			marginLeft: '12px',
			fontFamily: RigrafExpanded.light,
		},

		'@media (max-width: 599px)': {
			fontSize: tinyText.fontSize,
		},
	},
});
